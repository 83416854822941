import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import CTAButton from './CTAButton';
import Paragraph from './Paragraph';
import Wrap from './Wrap';

const AUTH_URL = '/.netlify/functions/auth';

const checkCode = async code => {
  try {
    const resp = await fetch(AUTH_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });
    const ok = (await resp.json()).ok;
    return resp.ok && ok;
  } catch {
    return false;
  }
};

const Prompt = ({ setAuth }) => {
  const [code, setCode] = useState('');

  const data = useStaticQuery(graphql`
    query {
      content: contentfulFormPage {
        enterCode
        submitButton
      }
    }
  `);

  return (
    <Wrap>
      <Center>
        <Paragraph>{data.content.enterCode}</Paragraph>
        <form css={{ textAlign: 'center' }}>
          <StyledInput
            value={code}
            onChange={e => setCode(e.target.value)}
            type="text"
          />
          <CTAButton
            isSubmit={true}
            onClick={async e => {
              e.preventDefault();
              const isOk = await checkCode(code);
              setAuth(isOk);
              return false;
            }}
          >
            {data.content.submitButton}
          </CTAButton>
        </form>
      </Center>
    </Wrap>
  );
};

export default Prompt;

const StyledInput = styled.input`
  display: block;
  padding: 12px;
  border: 1px solid grey;
  font-size: 1rem;
  border-radius: 6px;
  margin-bottom: 20px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
