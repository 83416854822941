import React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { parseISO, format } from 'date-fns';

import Paragraph from './Paragraph';
import PlayButton from './PlayButton';

const Updates = props => {
  const data = useStaticQuery(graphql`
    query {
      arrow: file(relativePath: { eq: "arrow-right.svg" }) {
        publicURL
      }
    }
  `);

  if (props.updates.nodes.length === 0) {
    return (
      <Paragraph
        css={{
          textAlign: 'center',
          padding: '30px 20px',
          background: '#eff5f7',
          borderRadius: 3,
        }}
        className={props.className}
      >
        There are no updates yet
      </Paragraph>
    );
  }

  return (
    <Container className={props.className}>
      <InnerContainer>
        {props.updates.nodes.map((update, index) => {
          const formattedDate = format(
            parseISO(update.createdAt),
            'dd-MM-yyyy'
          );

          return (
            <Tile key={index} to={`update/${update.slug}/`}>
              <ImgWrapper>
                <Img
                  alt={update.coverImage.title}
                  fluid={update.coverImage.fluid}
                />
                {update.coverVideo !== null && <PlayButton />}
                <ChallengeTitle>{update.challenge.title}</ChallengeTitle>
              </ImgWrapper>
              <Meta>
                {formattedDate} | <span>{update.author}</span>
              </Meta>
              <Title>{update.title}</Title>
              <Description>
                {update.text.childMarkdownRemark.excerpt}
              </Description>
              <MoreDetails>
                <span>More details</span>
                <MoreArrow image={data.arrow.publicURL} />
              </MoreDetails>
            </Tile>
          );
        })}
      </InnerContainer>
    </Container>
  );
};

export default Updates;

export const query = graphql`
  fragment ContentfulUpdateListView on ContentfulUpdate {
    author
    coverImage {
      title
      fluid(maxWidth: 480, maxHeight: 240, quality: 90, cropFocus: CENTER) {
        ...GatsbyContentfulFluid
      }
    }
    coverVideo {
      id
    }
    createdAt
    title
    slug
    text {
      childMarkdownRemark {
        excerpt(pruneLength: 160)
      }
    }
    challenge {
      title
    }
  }
`;

const Container = styled.section`
  margin: 40px 0;
`;

const InnerContainer = styled.div`
  ${p => p.theme.s} {
    margin: 0 -15px;
  }
  ${p => p.theme.ml} {
    margin: -16px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
`;

const Tile = styled(Link)`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 43, 92, 0.15);
  border: 1px solid #d9ebf3;
  background-clip: padding-box;
  text-decoration: none;
  color: inherit;
  ${p => p.theme.s} {
    display: block;
    margin: 0 auto;
    max-width: 480px;
    & + & {
      margin-top: 32px;
    }
  }
  ${p => p.theme.ml} {
    margin: 16px;
    flex: 0 1 calc(50% - 32px);
  }
`;

const ImgWrapper = styled.div`
  margin: -1px -1px 0;
  position: relative;
`;

const ChallengeTitle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  /* left: 50%;
  transform: translateX(-50%); */
  white-space: nowrap;
  border-top-left-radius: 5px;
  max-width: 100%;
  padding: 5px 8px 2px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  font-size: 15px;
  font-weight: bold;
  color: white;
`;

const Meta = styled.p`
  padding: 20px 30px 0;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  span {
    color: ${p => p.theme.color.accent};
  }
  font-size: 15px;
  ${p => p.theme.ml} {
    font-size: 16px;
  }
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  margin: 30px 0;
  padding: 0 30px;
`;

const Description = styled(Paragraph)`
  margin: 30px 0 50px;
  padding: 0 30px;
`;

const MoreDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
  padding: 0 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
`;

const MoreArrow = styled.div`
  background: url('${p => p.image}') no-repeat center center;
  background-size: contain;
  height: 18px;
  width: 18px;
  margin-left: 7px;
`;
