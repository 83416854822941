import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Wrap from '../components/Wrap';
import Prompt from '../components/Prompt';
import Updates from '../components/Updates';
import useAuth from '../hooks/useAuth';

const ChallengesPage = () => {
  const [isAuth, setAuth] = useAuth();

  const data = useStaticQuery(graphql`
    query {
      content: contentfulUpdatesPage {
        heroTitle1
        heroTitle2
        heroImage {
          fluid(maxWidth: 768, maxHeight: 512, cropFocus: CENTER, quality: 90) {
            ...GatsbyContentfulFluid
          }
        }
        loadOlderButton
      }
      updates: allContentfulUpdate(sort: { order: DESC, fields: createdAt }) {
        totalCount
        nodes {
          ...ContentfulUpdateListView
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Updates" />
      {isAuth ? (
        <>
          <Hero
            image={data.content.heroImage}
            title={[data.content.heroTitle1, data.content.heroTitle2]}
            titleIndent="second"
          />
          <Wrap>
            <Updates css={{ marginTop: 0 }} updates={data.updates} />
          </Wrap>
        </>
      ) : (
        <Prompt setAuth={setAuth} />
      )}
    </Layout>
  );
};

export default ChallengesPage;
