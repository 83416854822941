import { useState } from 'react';

const useAuth = () => {
  const key = 'auth20';
  const [isAuth, setInStore] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem(key) === 'true';
    }
    return false;
  });

  const set = value => {
    const valueString = value.toString();
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, valueString);
    }
    setInStore(value);
  }

  return [isAuth, set];
}

export default useAuth;
