import React from 'react';
import styled, { css } from 'styled-components';

const PlayButton = props => {
  return (
    <Button
      className={props.className}
      role="button"
      onClick={props.onClick}
      aria-label="Play video"
    />
  );
};

export default PlayButton;

const Button = styled.button`
  background: white;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ${p =>
    p.onClick
      ? css`
          :hover,
          :focus {
            opacity: 0.8;
          }
          :active {
            opacity: 0.7;
          }
        `
      : ''};
  :after {
    content: '';
    position: absolute;
    border-left: 25px solid ${p => p.theme.color.accent};
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -50%);
  }
`;
